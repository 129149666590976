<script>
import useVuelidate from '@vuelidate/core'
import {required} from '@vuelidate/validators'

/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkFormSubmitted from "@components/general/AkFormSubmitted";
import AkInputText from "@components/input/AkInputText";
import AkCalendar from "@components/input/AkCalendar";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import bilanCrisePeriodService from "@services/bilanCrisePeriodService";
import rolePermissionService from "@services/rolePermissionService";

export default {
  components: {AkFormView, AkFormSubmitted, AkCalendar, AkInputText},
  mixins: [randomRef, roleMixin],
  setup() {
    return {v$: useVuelidate()}
  },
  metaInfo() {
    return {
      title: "bilan_crise.period_create",
    }
  },
  data() {
    return {
      submitted: false,
      current: {},
    }
  },
  validations() {
    return {
      current: {
        name: {required},
        dateStart: {required},
        dateEnd: {required},
      },
    }
  },
  mounted() {
    this.getRef().showTotalLoader();
    let p1 = rolePermissionService.authorizeAccessByPermission(this.permissionConst.crisisReview.edit);
    Promise.all([p1]).then(()=>{
      this.getRef().hideLoader();
    });
  },
  methods: {
    create() {
      if (!this.checkForm()) {
        this.getRef().error(this.$t("bilan.error.create"));
        return;
      }
      
      if(this.current.dateEnd<this.current.dateStart) {
        this.getRef().error(this.$t("bilan.error.dates"));
        return;
      }
      
      this.getRef().showLoader(this.$refs.form)
      bilanCrisePeriodService.create(this.current).then(data => {
        let msg = this.$t("bilan_crise.period_created");
        this.$router.replace({ path: `/bilan/crise/period/${data.id}/details`, query: { msg: msg } });
      }).catch(e=>{
          this.getRef().hideLoader();
          this.getRef().error(this.$t("error."+e.response.data.error));
          this.submitted = false;
        }
      );
    },
    checkForm() {
      this.getRef().resetMessages();
      this.submitted = true;
      this.v$.$touch();
      return !this.v$.$error;
    }
  }
}
</script>

<template v-if=!roleLoading>
  <AkFormView :ref="ref" :title="$t('bilan_crise.period_create')">
    <template v-slot:form>
      <AkFormSubmitted :submitted=submitted reference="form">
        <div class="form-row">
          <AkInputText v-model="current.name"
                       :validator="v$.current.name"
                       :label="$t('bilan_crise.name')"
                       class-name="col-md-4"/>
        </div>
        <div class="form-row">
          <AkCalendar v-model="current.dateStart"
                      :validator="v$.current.dateStart"
                      :label="$t('bilan_crise.date_start')"
                      class-name="col-md-3"/>
          <AkCalendar v-model="current.dateEnd"
                      :validator="v$.current.dateEnd"
                      :label="$t('bilan_crise.date_end')"
                      class-name="col-md-3"/>
        </div>
        <div class="float-right">
          <button v-if=this.canCreateCrisis() @click=create() class="btn btn-primary" style="margin-left: 10px">{{ $t('save') }}</button>
        </div>
      </AkFormSubmitted>
    </template>
  </AkFormView>
</template>